/**
 * Define all of your application routes here
 * For more information on routes, see the official documentation https://router.vuejs.org/en/
 */

export default [
	{
		path: '/admin/login',
		meta: {
			name: 'External',
			requiresAuth: false,
		},
		component: () => import('@/views/admin/ExternalView.vue'),
		children: [
			{
				path: '',
				name: 'login',
				component: () => import('@/views/admin/LoginView.vue'),
			},
		],
	},
	{
		path: '/admin',
		meta: {
			name: 'dashboard-view',
			requiresAuth: true,
		},
		component: () => import('@/views/admin/DashboardView.vue'),
		children: [
			{
					path: '', // Đường dẫn trống để chuyển hướng
					redirect: '/admin/user', // Chuyển hướng từ path '/' sang '/home'
			},
			{
				path: 'user',
				meta: {
					name: 'UserList',
				},
				component: () => import('@/views/admin/DashboardViews/UserList.vue'),
			},
			{
				path: 'list',
				meta: {
					name: 'AdminList',
				},
				component: () => import('@/views/admin/DashboardViews/AdminList.vue'),
				beforeEnter: (to, from, next) => {
					const isAdmin = localStorage.getItem('role');
					if (isAdmin === 'ROOT') {
						next(); // Allow navigation to the requested route
					} else {
						next('/auth/user');
					}
				},
			},
			{
				path: 'config',
				meta: {
					name: 'AdminConfig',
				},
				component: () => import('@/views/admin/DashboardViews/AdminConfig.vue'),
				beforeEnter: (to, from, next) => {
					const isAdmin = localStorage.getItem('role');
					if (isAdmin === 'ROOT') {
						next(); // Allow navigation to the requested route
					} else {
						next('/auth/user');
					}
				},
			},
			{
				path: 'user/:id',
				meta: {
					name: 'UserEdit',
				},
				component: () => import('@/views/admin/DashboardViews/EditUser.vue'),
			},
			{
				path: 'product',
				meta: {
					name: 'Product',
				},
				component: () => import('@/views/admin/DashboardViews/Product.vue'),
			},
			{
				path: 'history-money',
				meta: {
					name: 'HistoryMoney'
				},
				component: () => import('@/views/admin/DashboardViews/HistoryMoney.vue'),

			},
			{
				path: 'recharge',
				meta: {
					name: 'Recharge',
				},
				component: () => import('@/views/admin/DashboardViews/Recharge.vue'),
			},
			{
				path: 'withdraw',
				meta: {
					name: 'Withdraw',
				},
				component: () => import('@/views/admin/DashboardViews/Withdraw.vue'),
			},
			
			{
				path: 'cardsview',
				meta: {
					name: 'CardsView',
				},
				component: () => import('@/views/admin/DashboardViews/CardsView.vue'),
			},
		],
		beforeEnter: (to, from, next) => {
			const isAdmin = localStorage.getItem('role');
			if (isAdmin == 'ADMIN' || isAdmin == 'ROOT') {
				next(); // Allow navigation to the requested route
			} else {
				next('/auth/login');
			}
		},
	},
	{
		path: '/',
		meta: {
			name: 'Index',
			requiresAuth: false,
		},
		component: () => import('@/views/user/index.vue'),
		children: [
			{
				path: '', // Đường dẫn trống để chuyển hướng
				redirect: '/home', // Chuyển hướng từ path '/' sang '/home'
			  },
			{
				path: 'auth/login',
				meta: {
					name: 'LoginUser',
				},
				component: () => import('@/views/user/auth/Login.vue'),
				beforeEnter: (to, from, next) => {
					// Kiểm tra nếu người dùng đã đăng nhập, chuyển hướng về '/home'
					const isAuthenticated = !!localStorage.getItem('access_token');
					if (isAuthenticated) {
					  next('/home');
					} else {
					  next(); // Tiếp tục điều hướng vào trang đăng nhập
					}
				  },
			},
			{
				path: 'auth/register',
				meta: {
					name: 'Register',
				},
				component: () => import('@/views/user/auth/Register.vue'),
				beforeEnter: (to, from, next) => {
					// Kiểm tra nếu người dùng đã đăng nhập, chuyển hướng về '/home'
					const isAuthenticated = !!localStorage.getItem('access_token');
					if (isAuthenticated) {
					  next('/home');
					} else {
					  next(); // Tiếp tục điều hướng vào trang đăng nhập
					}
				  },
			},
			{
				path: 'home',
				meta: {
					name: 'Home',
					requiresAuth: true,
				},
				component: () => import('@/views/user/Home.vue'),
			},
			{
				path: 'invest',
				meta: {
					name: 'Invest',
					requiresAuth: true,
				},
				component: () => import('@/views/user/Invest.vue'),
			},
			{
				path: 'binkbank',
				meta: {
					name: 'BinkBank',
					requiresAuth: true,
				},
				component: () => import('@/views/user/BindBank.vue'),
			},
			{
				path: 'invite',
				meta: {
					name: 'Invite',
					requiresAuth: true,
				},
				component: () => import('@/views/user/Invite.vue'),
			},
			{
				path: 'money-history',
				meta: {
					name: 'MoneyHistory',
					requiresAuth: true,
				},
				component: () => import('@/views/user/MoneyHistory.vue'),
			},
			{
				path: 'my',
				meta: {
					name: 'My',
					requiresAuth: true,
				},
				component: () => import('@/views/user/My.vue'),
			},
			{
				path: 'recharge',
				meta: {
					name: 'Recharge',
					requiresAuth: true,
				},
				component: () => import('@/views/user/Recharge.vue'),
			},
			{
				path: 'wallet',
				meta: {
					name: 'Wallet',
					requiresAuth: true,
				},
				component: () => import('@/views/user/Wallet.vue'),
			},
			{
				path: '/withdraw',
				meta: {
					name: 'Withdraw',
					requiresAuth: true,
				},
				component: () => import('@/views/user/Withdraw.vue'),
			},
			{
				path: '/rank',
				meta: {
					name: 'Rank',
					requiresAuth: true,
				},
				component: () => import('@/views/user/Rank.vue'),
			},
			{
				path: '/edit-pw',
				meta: {
					name: 'editPw',
					requiresAuth: true,
				},
				component: () => import('@/views/user/EditPass.vue'),
			},
			{
				path: '/cskh',
				meta: {
					name: 'Cskh',
				},
				component: () => import('@/views/user/Cskh.vue'),
			},
		],
	},
	{
		path: '*',
		redirect: {
			name: 'catchAll',
			path: '/',
		},
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/:catchAll(.*)',
		component: () => import('@/components/error/NotFound.vue'),
		name: 'NotFound',
	},
];
