<template>
	<!-- userSnippet.vue menu -->
	<v-card color="background" min-width="250">
		<v-container class="text-center">
            <img src="@/assets/img/user-default.svg" alt="">
			<h3> {{user.name}}</h3>
			<!-- <v-row justify="center" class="mb-1">
				<v-col cols="3" justify-center>
					<router-link to="user">
						<v-avatar size="70">
							<img :src="getAvatar" alt="User Avatar" />
						</v-avatar>
					</router-link>
				</v-col> -->
				<!-- <div class="text-center">
					<h3>{{user.name}}</h3>
				</div> -->
				<!-- <v-col cols="9" align-self="auto">
					<v-card-title class=""> Travis Scott </v-card-title>
					<v-card-subtitle>
						<v-divider />
						Adminstrator
					</v-card-subtitle>
				</v-col> -->
			<!-- </v-row> -->
			<!-- these controls the search displaying in userSnippet -->
			<!-- <v-divider /> -->
			<!-- this controls if the search box is shown -->
			
			<!-- < -->
		</v-container>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import network from "@/core/network"
export default {
	name: "UserSnippet",
	props: {
		buttonColor: { default: "tertiary" },
		showBottom: { default: true },
		showSearch: { default: true },
		showBtn: { default: true },
	},
	data() {
		return {
			message: null,
			user: {}
		};
	},
	computed: {
		...mapGetters(["getAvatar"]),
	},
	mounted() {
		this.getProfile()
	},
	methods: {
		getProfile() {
			network.get('/users/profile').then( res => {
			console.log(res.data.data);
			this.user = res.data.data;
			}).catch(err => {
			console.log(err);
			})
      },
	},
};
</script>

<style></style>
