<template>
    <div id="app">
        <v-app id="inspire">
            <v-card class="mx-auto w-100" style="width: 100%;" outlined >
                <v-card-title></v-card-title>
                <v-data-table :headers="table?.headers" :search="search" :items="table?.desserts"
                    :items-per-page="table?.itemsPerPage" :page="table?.page" :server-items-length="table?.totalItems"
                    hide-default-footer class="elevation-1 " width="100%" fixed-header height="auto"
                    style="width: 100%;">
                    <v-divider inset></v-divider>
                    <template v-slot:top>
                        <v-toolbar flat>
                            <div class="d-flex w-100">
                                <v-text-field outlined v-model="message" label="Search.." prepend-inner-icon="mdi-magnify"
                                    outlined clearable dense color="green" @input="searchData">
                                </v-text-field>
                                <!-- <v-btn color="primary" class="ml-2 white--text" @click="addNew">
                                    <v-icon dark>mdi-plus</v-icon>Add
                                </v-btn> -->
                            </div>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <v-text-field outlined v-model="item.name" :hide-details="true" dense single-line :autofocus="item.id === editedItem.id"
                            v-if="item.id === editedItem.id"></v-text-field>
                        <span v-else>{{ item.name }}</span>
                    </template>
                    <template v-slot:item.balance="{ item }">
                        {{ formatMoney(item.balance) }}  đ
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ formatDate(item.created_at) }}
                    </template>
                    <template v-slot:item.calories="{ item }">
                        <v-text-field outlined v-model="item.calories" :hide-details="true" dense single-line
                            v-if="item.id === editedItem.id"></v-text-field>
                        <span v-else>{{ item.calories }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div v-if="item.id === editedItem.id">
                            <v-icon color="red" class="mr-3" @click="close">
                                mdi-window-close
                            </v-icon>
                            <v-icon color="green" @click="save">
                                mdi-content-save
                            </v-icon>
                        </div>
                        <div v-else>
                            <v-icon color="green" class="mr-3" @click="editItem(item._id)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="red" @click="deleteItem(item._id)">
                                mdi-delete
                            </v-icon>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">Reset</v-btn>
                    </template>

                    <!-- footer -->
                    <template v-slot:footer>
                        <v-container>
                            <v-row justify="end">
                                <v-col cols="8">
                                    <v-container class="max-width">
                                        <v-pagination 
                                            v-model="table.page"
                                            :length="Math.ceil(table.totalItems / table.itemsPerPage)" class="my-4"
                                            @input="fetchData"></v-pagination> 
                                        </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </v-data-table>

            </v-card>
        </v-app>
    </div>
</template>

<script>
export default {
    props: {
        table: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        message: '',
        search: '',
        editedItem: {
            id: 0,
            name: '',
            calories: 0
        },
        defaultItem: {
            id: 0,
            name: 'New Item',
            calories: 0
        }
    }),

    methods: {
        fetchData() {
            // You may need to adjust the event name here based on your application's setup
            this.$emit('fetch-data', this.table.page);
        },

        searchData() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.$emit('search-data', this.message);
            }, 500);
        },

        save() {
            if (this.editedItem.id !== 0) {
                const index = this.table.desserts.findIndex(item => item.id === this.editedItem.id);
                if (index !== -1) {
                    this.$set(this.table.desserts, index, { ...this.editedItem });
                }
            }
            this.close();
        },
        editItem(id) {
                this.$emit('edit', id);
        },

        deleteItem(id) {
            if (confirm('Bạn chắc chắn muốn xoá?')) {
                this.$emit('delete', id);
            }
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        },
        formatMoney(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
}
</script>

<style></style>
